<template>
	<tr class="align-middle">
		<td class="text-center"><img :src="thumb" class="user-thumb"></td>
		<td>{{ userName }}</td>
		<td>{{ status }}</td>
		<td>{{ dateStarted }}</td>
		<td class="text-right pr-5">
			<div class="d-flex flex-row justify-content-end">
				<p class="clickable text-brand font-weight-bold text-decoration-underline mb-0" @click="seeResult">Quiz results</p>
				<p
					v-if="!!dateCompleted"
					class="ml-6 clickable text-brand font-weight-bold text-decoration-underline mb-0"
					@click="seeCertificate"
				>
					View certificate
				</p>
			</div>
			<certificate-template
				v-if="displayPopup === true"
				:userName="userName"
				:courseTitle="courseTitle"
				:completed="isComplete"
				:dateCompleted="dateCompleted"
				@closePopup="closePopup"
			></certificate-template>
		</td>
	</tr>
	<!-- <article
		class="course-analytics-row-container d-flex flex-row align-center mb-4"
	>
		<section
			class="course-analytics-row d-flex flex-row justify-start align-center mr-2"
		>
			<p class="course-analytics-row-info mr-6">{{ userName }}</p>
			<p class="course-analytics-row-info mr-6">{{ status }}</p>
			<p class="course-analytics-row-info mr-6">{{ dateStarted }}</p>
			<p class="course-analytics-row-info mr-6">{{ dateCompleted }}</p>
		</section>
		<section
			class="buttons-container d-flex flex-row justify-start align-center ml-2"
		>
			<p class="clickable" @click="seeResult">Quiz results</p>
			<p
				v-if="!!dateCompleted"
				class="ml-6 clickable"
				@click="seeCertificate"
			>
				View certificate
			</p>
		</section>
		<certificate-template
			v-if="displayPopup === true"
			:userName="userName"
			:courseTitle="courseTitle"
			:completed="isComplete"
			:dateCompleted="dateCompleted"
			@closePopup="closePopup"
		></certificate-template>
	</article> -->
</template>

<script>
import CertificateTemplate from "@/views/common/CertificateTemplate.vue";

export default {
	name: "CourseAnalyticsAlignedUser",
	components: { CertificateTemplate },
	props: ["user"],
	computed: {
		userName() {
			if (!!this.user === false) return;
			return this.user.user.name;
		},
		courseTitle() {
			if (!!this.user === false) return;
			return this.user?.course.title;
		},
		status() {
			let status = 'Not Started'
			if(this.user.report_analytics.status != null) {
				status = this.user.report_analytics.status
			}

			return status
		},
		thumb() {
			return require('../../../../assets/noimage.svg')
		},
		isComplete() {
			if (!!this.user?.report_analytics?.is_completed === false) return;
			return this.user?.report_analytics?.is_completed;
		},
		dateStarted() {
			if (!!this.user?.report_analytics === false) return;
			return `${new Date(
				this.user.report_analytics.created_at.slice(0, 10)
			).toLocaleDateString()}`;
		},
		dateCompleted() {
			if (!!this.user?.report_analytics?.completed_at === false) return;
			return `${new Date(
				this.user.report_analytics.completed_at.slice(0, 10)
			).toLocaleDateString()}`;
		}
	},
	data() {
		return {
			displayPopup: false
		};
	},
	methods: {
		seeResult() {
			console.log("results");
		},
		seeCertificate() {
			this.displayPopup = true;
		},
		closePopup() {
			this.displayPopup = false;
		}
	}
};
</script>

<style lang="scss" scoped>
p {
	white-space: nowrap;
}
.course-analytics-row-container {
	background-color: rgb(230, 230, 230);
	padding: 10px 20px;
}
.course-analytics-row {
	width: 100%;
	&-info {
		width: 25%;
	}
	& section {
		width: 100%;
	}
}
.buttons-container {
	width: 25%;
}
</style>
